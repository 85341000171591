import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {
    Box, Checkbox,
    Container, FormControl, FormControlLabel, FormLabel, Grid, Link,
    Radio, RadioGroup, TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Navigate} from "react-router-dom";
import '../App.css';
import FormatUtils from "../utils/FormatUtils";
import {PaymentType} from "../constants/PaymentType";
import {CanPayByInvoiceType} from "../constants/CanPayByInvoiceType";
import {TireChoiceType} from "../constants/TireChoiceType";
import {ServiceType} from "../constants/ServiceType";
import MobileContext from "../contexts/mobileContext";
import ConfigurationContext from "../contexts/configurationContext";

function ServiceStep4({
                          previousBtn,
                          nextBtn,
                          setLabel,
                          vehicle,
                          tires,
                          location,
                          service,
                          serviceOptions,
                          day,
                          period,
                          comment,
                          userData,
                          validatePayment,
                          tireChoice,
                          quotationComment,
                          valetData,
                          canPayByCash,
                      }, ref) {

    const {t, i18n} = useTranslation('common');
    const token = localStorage.getItem('accessToken');

    const [loaded, setLoaded] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [gtcuAccepted, setGtcuAccepted] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState();

    const paymentMethodRef = useRef(null);
    paymentMethodRef.current = paymentMethod;

    const invoiceDetailsRef = useRef();
    invoiceDetailsRef.current = invoiceDetails;

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const applicationSettingsContext = useContext(ConfigurationContext);

    useEffect(() => {
        setLabel('service.step4.title');

        previousBtn.current.style.display = '';

        nextBtn.current.addEventListener('click', nextClickedHandler);
        setLoaded(true);

        // TODO destructuring ?
        setInvoiceDetails({
            invoice_cost_center: vehicle.invoice_cost_center,
            invoice_name: vehicle.invoice_name,
            invoice_address: vehicle.invoice_address,
            invoice_postal_code: vehicle.invoice_postal_code,
            invoice_city: vehicle.invoice_city,
        })

        if (tireChoice == TireChoiceType.BUY) {
            setPaymentMethod(PaymentType.QUOTATION);
        }

        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    useEffect(() => {
        if (paymentMethod == null || !gtcuAccepted) {
            nextBtn.current.style.display = 'none';
        } else {
            nextBtn.current.style.display = '';
        }
    }, [paymentMethod, gtcuAccepted]);

    // useEffect(() => {
    //     // console.log("serviceOptions", serviceOptions);
    // }, [serviceOptions])

    if (token == null) {
        return ( //
            <Navigate to='/login'/>
        );
    }

    function nextClickedHandler() {
        //
        validatePayment(paymentMethodRef.current, invoiceDetailsRef.current);
    }

    // TODO Utiliser FormatUtils
    function formatPeriod(period, day) {
        let result = '';
        let schedules = '';
        if (period == 'am') {
            result += t('common.am');
            schedules += day.am_start + ' - ' + day.am_end;
        } else {
            result += t('common.pm');
            schedules += day.pm_start + ' - ' + day.pm_end;
        }
        result += ' (' + schedules + ')';
        return result;
    }

    function canPayByInvoice() {
        // console.log('canPayByInvoice', userData.can_pay_by_invoice_type, location.pay_by_invoice_type, location.service_type_id, tireChoice )
        if (userData.can_pay_by_invoice_type == CanPayByInvoiceType.YES
            && location.pay_by_invoice_type != null
            && (location.service_type_id == ServiceType.CARWASH ||
                location.service_type_id == ServiceType.TIRES && tireChoice == TireChoiceType.MINE)
        ) {
            return true;
        }
        return false;
    }

    function isLeasingOrder() {
        // console.log('isLeasingOrder', vehicle.leasing_company, location.service_type_id);
        // TODO Attention c'est le client qui décide si c'est une commande de leasing, pas top..
        if (
            vehicle.leasing_company != null
            && location.service_type_id == ServiceType.TIRES
        ) {
            return true;
        }
        return false;
    }

    function getTotal() {
        let total = 0;
        total += parseFloat(service.price.price);
        serviceOptions.forEach((serviceOption, i) => {
            if (serviceOption.type == 'valet') {
                total += FormatUtils.convertAmountCentToDecimal(valetData.price);
            } else {
                total += parseFloat(serviceOption.price.price);
            }
        });
        return total;
    }

    // Renvoi la liste des moyens de paiement
    function getAvailablePaymentMethods() {
        let paymentMethods = [];
        if (isLeasingOrder()) {
            paymentMethods.push({
                "id": PaymentType.LEASING_INVOICE,
                "label": FormatUtils.changeTextWithValues(t('service.step4.payment_leasing'), {'leasing_company': vehicle.leasing_company.label})
            });
        } else {
            paymentMethods.push({"id": PaymentType.CREDIT_CARD, "label": t('service.step4.payment_credit_card')});
            if (canPayByCash == "1") {
                paymentMethods.push({"id": PaymentType.CASH, "label": t('service.step4.payment_cash')});
            }
            if (canPayByInvoice()) {
                paymentMethods.push({
                    "id": PaymentType.INVOICE,
                    "label": t('service.step4.payment_by_invoice')
                })
            }
        }
        return paymentMethods;
    }

    let availablePaymentMethods = getAvailablePaymentMethods();

    return (
        <Container maxWidth={false}>
            {loaded &&
                <>
                    {/*<ApplicationSettingsService setApplicationSettings={setApplicationSettings}/>*/}
                    <Box
                        mt={2}
                        p={2}
                        sx={{
                            backgroundColor: '#fbc70f',
                            color: 'black'
                        }}>

                        <Typography
                            align={isMobile ? 'center' : 'left'}
                            fontWeight={isMobile ? 'bold' : null}
                        >{t('service.step4.summary_label' + (isMobile ? '_mobile' : ''))}</Typography>

                        <Typography fontWeight="bold">{t('service.step4.summary_date')}</Typography>
                        <Typography>{FormatUtils.formatDayDate(day.date, i18n.language)}, {formatPeriod(period, day)}</Typography>

                        <Typography fontWeight="bold">{t('service.step4.summary_vehicle')}</Typography>
                        <Typography>{vehicle.brand} {vehicle.model}
                            <b> {vehicle.licence_plate}</b></Typography>
                        {
                            tires != null &&
                            <>
                                <Typography fontWeight="bold">{t('service.step4.summary_tire')}</Typography>
                                {tires.map((tire, i) => {
                                    return <Typography key={i}>{FormatUtils.formatTire(tire, t)}</Typography>
                                })}
                            </>
                        }
                        <Typography fontWeight="bold">{t('service.step4.summary_location')}</Typography>
                        <Typography>{location.name}</Typography>
                        <Typography>{location.address}, {location.postal_code} {location.city}</Typography>
                        <Typography fontWeight="bold">{t('service.step4.summary_services')}</Typography>
                        <Grid container>
                            <Grid item>
                                <Typography>{service.name}</Typography>
                            </Grid>
                            <Grid item ml={isMobile ? 'auto' : 1}>
                                <Typography>{FormatUtils.formatPrice(service.price.price)}</Typography>
                            </Grid>
                        </Grid>
                        {/*<Typography>{service.name} - {service.price.price} {service.currency}</Typography>*/}
                        {
                            serviceOptions.length > 0 && <Typography>{t('service.step4.summary_options')}</Typography>
                        }
                        {
                            serviceOptions.map((serviceOption, i) => {
                                return <Grid container key={i}>
                                    <Grid item>
                                        <Typography>{serviceOption.name}</Typography>
                                    </Grid>
                                    <Grid item ml={isMobile ? 'auto' : 1}>
                                        {serviceOption.type == 'valet' ?
                                            <Typography>{FormatUtils.formatAmount(valetData.price, true)}</Typography>
                                            :
                                            <Typography>{FormatUtils.formatPrice(serviceOption.price.price)}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                                //
                                //
                                // <Typography key={i}>
                                //     {serviceOption.name} - {serviceOption.price.price} {service.currency}
                                // </Typography>
                            })
                        }

                        <Grid container>
                            <Grid item>
                                <Typography fontWeight="bold">{t('service.step4.summary_total')}</Typography>
                            </Grid>
                            <Grid item ml={isMobile ? 'auto' : 1}>
                                <Typography fontWeight={'bold'}>{FormatUtils.formatPrice(getTotal())} {
                                    tireChoice == TireChoiceType.BUY && !isMobile && t('service.step4.total_with_quotation')
                                }</Typography>
                            </Grid>
                            {
                                tireChoice == TireChoiceType.BUY && isMobile &&
                                <Grid item ml={isMobile ? 'auto' : 1}>
                                    <Typography fontWeight={'bold'}>
                                        {t('service.step4.total_with_quotation')}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>

                        {quotationComment != null && quotationComment != '' &&
                            <>
                                <Typography fontWeight="bold">{t('service.step3.quotation_comment_label')}</Typography>
                                <Typography>{quotationComment}</Typography>
                            </>}
                        {comment != null && comment != '' &&
                            <>
                                <Typography fontWeight="bold">{t('service.step3.comment_label')}</Typography>
                                <Typography>{comment}</Typography>
                            </>
                        }
                    </Box>

                    <FormControl margin="dense" fullWidth={true}>
                        {
                            (location.service_type_id == ServiceType.CARWASH
                                ||
                                (location.service_type_id == ServiceType.TIRES && tireChoice == TireChoiceType.MINE))
                            &&
                            <>
                                <FormLabel
                                    style={{
                                        fontSize: isMobile ? '20px' : '24px'
                                    }}
                                    id="demo-row-radio-buttons-group-label">{t('service.step4.payment_type_label')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={paymentMethod}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                >
                                    {
                                        availablePaymentMethods.map((pm, i) => {
                                            return <FormControlLabel
                                                key={i}
                                                sx={{
                                                    fontSize: '30px'
                                                }} value={pm.id} control={<Radio/>}
                                                label={pm.label}/>
                                        })
                                    }
                                </RadioGroup>

                                {(paymentMethod == PaymentType.CASH || paymentMethod == PaymentType.INVOICE) &&
                                    <Box
                                        mt={2}
                                        mb={2}
                                        p={2}
                                        sx={{
                                            backgroundColor: '#fbc70f',
                                            color: 'black'
                                        }}>
                                        <Typography>{t(paymentMethod == PaymentType.CASH ? 'service.step4.cash_message' : 'service.step4.payment_by_invoice_explain')}</Typography>
                                    </Box>
                                }
                                {
                                    paymentMethod == PaymentType.INVOICE &&
                                    <Grid container>
                                        <Grid container item xs={12 / 5}
                                              justifyContent="center"
                                              sx={{
                                                  pl: 1,
                                                  pr: 1
                                              }}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label={t('service.step4.invoice_cost_center_label')}
                                                value={invoiceDetails.invoice_cost_center || ''}
                                                onChange={(e) => setInvoiceDetails({...invoiceDetails, ...{invoice_cost_center: e.target.value}})}
                                            />
                                        </Grid>
                                        <Grid item xs={12 / 5}
                                              justifyContent="center"
                                              sx={{
                                                  pl: 1,
                                                  pr: 1
                                              }}>

                                            <TextField
                                                required
                                                margin="normal"
                                                fullWidth
                                                label={t('service.step4.invoice_name_label')}
                                                value={invoiceDetails.invoice_name || ''}
                                                onChange={(e) => setInvoiceDetails({...invoiceDetails, ...{invoice_name: e.target.value}})}
                                            />
                                        </Grid>
                                        <Grid item xs={12 / 5}
                                              justifyContent="center"
                                              sx={{
                                                  pl: 1,
                                                  pr: 1
                                              }}>
                                            <TextField
                                                required
                                                margin="normal"
                                                fullWidth
                                                label={t('service.step4.invoice_address')}
                                                value={invoiceDetails.invoice_address || ''}
                                                onChange={(e) => setInvoiceDetails({...invoiceDetails, ...{invoice_address: e.target.value}})}
                                            />
                                        </Grid>
                                        <Grid item xs={12 / 5}
                                              justifyContent="center"
                                              sx={{
                                                  pl: 1,
                                                  pr: 1
                                              }}>
                                            <TextField
                                                required
                                                margin="normal"
                                                fullWidth
                                                label={t('service.step4.invoice_postal_code')}
                                                value={invoiceDetails.invoice_postal_code || ''}
                                                onChange={(e) => setInvoiceDetails({...invoiceDetails, ...{invoice_postal_code: e.target.value}})}
                                            />
                                        </Grid>
                                        <Grid item xs={12 / 5}
                                              justifyContent="center"
                                              sx={{
                                                  pl: 1,
                                                  pr: 1
                                              }}>
                                            <TextField
                                                required
                                                margin="normal"
                                                fullWidth
                                                label={t('service.step4.invoice_city')}
                                                value={invoiceDetails.invoice_city || ''}
                                                onChange={(e) => setInvoiceDetails({...invoiceDetails, ...{invoice_city: e.target.value}})}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }
                        {
                            tireChoice == TireChoiceType.BUY &&
                            <Typography>{t('service.step4.offer_message')}</Typography>
                        }
                        <FormControlLabel value="gtcu"
                                          control={<Checkbox/>}
                                          label={
                                              <>
                                                  <Typography>
                                                      {t('service.step4.gtcu_label')}
                                                      {/*<Link to="/cgv" style={*/}
                                                      {/*    {*/}
                                                      {/*        textDecoration: 'none',*/}
                                                      {/*        color: '#fbc70f'*/}
                                                      {/*    }*/}
                                                      {/*}>*/}
                                                      {/*    {t('service.step4.gtcu_clickable_label')}*/}
                                                      {/*</Link>*/}
                                                      <Link
                                                          style=
                                                              {{
                                                                  textDecoration: 'none',
                                                                  color: '#fbc70f'
                                                              }}
                                                          // https://keepupcar.com/wp-content/uploads/2018/03/CGV-Keep-Up-Car-1.pdf
                                                          href={applicationSettingsContext == null ? '' : applicationSettingsContext.URL_CGV}
                                                          target="_blank"
                                                      >
                                                          {t('service.step4.gtcu_clickable_label')}
                                                      </Link>
                                                  </Typography>
                                              </>
                                          }
                                          checked={gtcuAccepted}
                                          onChange={(e) => setGtcuAccepted(e.target.checked)}/>
                    </FormControl>
                </>
            }
        </Container>
    )
};

export default forwardRef(ServiceStep4);