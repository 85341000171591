import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Box, Button, Container, Grid, Modal
} from "@mui/material";
import {
    useLocation,
    useOutletContext,
    useSearchParams
} from "react-router-dom";

import btnClose from "../assets/buttons/close/close-nobackground.svg";
import btnCloseOver from "../assets/buttons/close/close-nobackground.svg";
import ImageButton from "./ImageButton";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {useTranslation} from "react-i18next";
import ServiceStepper from "./ServiceStepper";
import Swal from "sweetalert2";
import ServiceSuccess from "./ServiceSuccess";
import ServiceStep1 from "./ServiceStep1";
import ServiceStep2 from "./ServiceStep2";
import ServiceStep3 from "./ServiceStep3";
import ServiceStep4 from "./ServiceStep4";
import ServiceStepStripe from "./ServiceStepStripe";
import ServiceStepPaymentMethods from "./ServiceStepPaymentMethods";
import ServiceStepChooseTire from "./ServiceStepChooseTire";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import {ServiceType} from "../constants/ServiceType";
import {PaymentType} from "../constants/PaymentType";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import FormatUtils from "../utils/FormatUtils";


// Très important que ce soit en dehors du composant ( pour éviter des render à ralonge )
function ParentTag({
                       children,
                       isBranded
                   }) {
    if (isBranded) {
        return <Container
            maxWidth={false}
            style={{
                padding: '0px',

            }}>
            {children}
        </Container>;
    } else {
        return <Modal
            open={true}
            style={{
                overflowY: 'scroll',
                // marginTop : '50vh',
                // display:'flex',
                // alignItems : 'center',
                // justifyContent : 'center'
            }}
            disableEnforceFocus
        >{children}
        </Modal>
    }
}

export default function ServiceModal({brandData = null}) {
    // Steps
    const [activeStep, setActiveStep] = useState(0);    // Step de 0 à 3
    const [paymentStep, setPaymentStep] = useState(null);
    const [chooseTireStep, setChooseTireStep] = useState(false);

    const PaymentStep = {
        ChooseCard: 'ChooseCard',
        NewCard: 'NewCard'
    }

    const [contentLoaded, setContentLoaded] = useState(false);

    const previousBtnRef = useRef();
    const nextBtnRef = useRef();

    const childRef = useRef();
    const invoiceDetailsRef = useRef();

    const [apiUri, vehicleUpdatedHandler, orderUpdatedHandler, stripeDoneHandler] = useOutletContext();
    const {t} = useTranslation('common');

    const [label, setLabel] = useState('service.modal_default_title');

    const [userData, setUserData] = useState(null);

    // Step 1
    const [vehicle, setVehicle] = useState(null);

    // Step 2
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTimeslot, setSelectedTimeslot] = useState(null);

    // Step choose tires
    const [selectedTires, setSelectedTires] = useState(null);
    const [tireChoice, setTireChoice] = useState(null); // mine / buy
    const [quotationComment, setQuotationComment] = useState(null); // mine / buy

    // Step 3
    const [selectedService, setSelectedService] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [retrieveHour, setRetrieveHour] = useState(null);
    const [comment, setComment] = useState(null);
    const [phone, setPhone] = useState(null);
    const [canPayByCash, setCanPayByCash] = useState(true);

    // Step 3 => Valet
    const [valetUserAddress, setValetUserAddress] = useState(null);
    const [valetData, setValetData] = useState(null);   // TODO rename as valetIntentData ?
    const [valetComment, setValetComment] = useState('');

    // Type de paiement => 1 = CB , 2 = Cash, etc..
    const [paymentType, setPaymentType] = useState(null);

    // Les cartes enregistrées
    const [paymentMethodsData, setPaymentMethodsData] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [orderDone, setOrderDone] = useState(false);

    const [orderData, setOrderData] = useState(null);
    const [stripeIntentData, setStripeIntentData] = useState(null);

    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const {call, callPost, isSending} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);

    function contentLoadedHandler() {
        // console.log("contentLoadedHandler!");
        setContentLoaded(true);
        // TODO A Virer ?
        // setVehicleData(locationData);
    }

    // useEffect(() => {
    //     console.log('BD:', brandData);
    // }, [brandData]);

    useEffect(() => {
        // console.log('SM : FIRST RENDER!');
        accountGet();
    }, []);

    useEffect(() => {
        // Branded : vehicle not needed
        if (isBranded) {
            setActiveStep(1);
        } else {
            // Check if vehicle already set
            if (location.state != null && 'vehicle' in location.state) {
                validateVehicle(location.state.vehicle);
            }
        }

        if (location.pathname == "/stripe_payment_callback") {
            // payment_intent=pi_3LwrBPHCGT44hOrH04groqmR&payment_intent_client_secret=pi_3LwrBPHCGT44hOrH04groqmR_secret_MK1zkJK2BxjsEuqIooOSyusfS&redirect_status=succeeded
            setOrderDone(true);
            // orderUpdatedHandler(orderData); // orderData is null in this case
            stripeDoneHandler(searchParams.get('vehicleId'));
        }

    }, [location]);


    useEffect(() => {
        switch (paymentStep) {
            case null:
                setPaymentMethod(null);
                break;
            case PaymentStep.ChooseCard:
                break;
            case PaymentStep.NewCard:
                setPaymentMethod(null);
                requestPaymentIntentFromServer(paymentType);
                break;
        }
    }, [paymentStep]);

    // Cas ou on paye en cb, on a intérogé la liste des carte sauvegardées
    useEffect(() => {
        if (paymentMethodsData == null) {
            return;
        }

        let cardCount = 0;
        if ('data' in paymentMethodsData) {
            cardCount = paymentMethodsData.data.length
        }

        if (cardCount == 0) {
            setPaymentStep(PaymentStep.NewCard);
        } else {
            setPaymentStep(PaymentStep.ChooseCard);
        }
    }, [paymentMethodsData]);

    useEffect(() => {
        if (paymentMethod != null) {
            requestPaymentIntentFromServer(paymentType, paymentMethod);
        }
    }, [paymentMethod]);

    useEffect(() => {
        console.log('selectedLocation:', selectedLocation);
    }, [selectedLocation]);

    // TODO Repetition avec Profile.js
    async function accountGet() {
        call(apiUri + 'account', (data) => {
            setUserData(data.user);
        });
    }

    async function requestPaymentIntentFromServer(pPaymentType, pPaymentMethod = null) {
        let data = {
            'vehicle_id': vehicle.id,
            'tires': selectedTires,
            // 'tire_id': selectedTires == null ? null : selectedTires.id,
            'location_id': selectedLocation.id,
            'date': selectedDay.date,
            'time_slot': selectedTimeslot,
            'payment_type_id': pPaymentType,
            'currency': selectedService.currency,
            'payment_method_id': pPaymentMethod != null ? pPaymentMethod.id : null,
            'retrieve_hour': retrieveHour,
            'comment': comment,
            'phone': phone,
            'valet_comment': valetComment,
            'invoice_details': invoiceDetailsRef.current,
            'quotation_comment': quotationComment,
            'location_group_id': brandData?.location_group.id
        }

        let detail = [];
        detail.push({
            'service_id': selectedService.id
        });

        // TODO Valet
        selectedOptions.forEach((option, id) => {
            if (option.isStoreTire) {
                detail.push({
                    'service_id': selectedService.id,
                    'type': 'tire_storage'
                });
            } else if (option.type == 'valet') {
                detail.push({
                    'service_id': selectedService.id,
                    'type': 'valet',
                    'valet_intent_id': valetData.id
                });
            } else {
                detail.push({
                    'service_id': selectedService.id,
                    'option_id': option.id
                });
            }
        });

        data.detail = detail;

        callPost(apiUri + 'order_intent', data, (resultData => {
            setOrderData(resultData.order);
            if (resultData.order.order_status_id == '1') {    // Cash
                Swal.fire({
                    text: t('service.step4.success'),
                    icon: 'success',
                    timer: 2000,
                    target: document.getElementById('swal_container'),
                })
                    .then(() => {
                        setOrderDone(true);
                        orderUpdatedHandler(resultData.order);
                    });
            } else if (resultData.order.order_status_id == '9') {    // Devis
                Swal.fire({
                    text: t('service.step4.success'),
                    icon: 'success',
                    timer: 2000,
                    target: document.getElementById('swal_container'),
                })
                    .then(() => {
                        setOrderDone(true);
                        orderUpdatedHandler(resultData.order);
                    });
            } else {
                if (paymentMethod != null) {    // TODO UTiliser plutot le retour du paiement pour voir que c'est un paiement auto ? On a pas grand chose , un payment_method ? Qui est rempli aussi dans le cas du paiement manuel
                    // Paiement Auto
                    setOrderDone(true);
                    orderUpdatedHandler(resultData.order);
                    // stripeDoneHandler(searchParams.get('vehicleId'));
                } else {
                    // CB manuel
                    setStripeIntentData(resultData.intent);
                }
            }
        }));
    }

    // TODO SAme as PaymentMethods.js
    async function requestPaymentMethods() {
        call(apiUri + 'customer_payment_methods', (resultData => {
            setPaymentMethodsData(resultData.payment_methods);
        }));
    }

    // User clicked on a different step => Notify current content
    function changeStepFromMenuHandler(e, stepValue) {
        // console.log('changeStepFromMenuHandler');
        childRef.current.wantChangeTab(stepValue);
    }

    function getCurrentStepTag(stepValue, tireStep) {
        let tag;

        if (tireStep) {
            return <ServiceStepChooseTire/>;
        }

        switch (stepValue) {
            case 0 :
                tag = <ServiceStep1/>;
                break;
            case 1 :
                tag = <ServiceStep2/>;
                break;
            case 2 :
                tag = <ServiceStep3/>;
                break;
            case 3 :
                tag = <ServiceStep4/>;
                break;
        }
        // console.log('Current Tag:', tag);
        return tag;
    }

    function closeButtonHandler() {
        vehicleUpdatedHandler();
    }

    // Validation step1 or step2 branded
    function validateVehicle(vehicle, changeStep = true) {
        // console.log('validateVehicle changeStep ?', changeStep);
        setVehicle(vehicle);
        if (changeStep) {
            setActiveStep(1);
        }
    }

    // Validation step2
    function locationSelectedHandler(location, day, period) {
        // On vire ce qui a pu être selectionné avant ( cas d'un back )
        if (selectedLocation != location) {
            setSelectedService(null);
            setSelectedOptions([]);
            setRetrieveHour(null);
            setComment(null);
            setValetData(null);
        }

        setSelectedLocation(location);
        setSelectedDay(day);
        setSelectedTimeslot(period);

        // Cas d'un presta de pneus => Step 2.5 , choix du pneu
        if (location.service_type_id == ServiceType.TIRES) {
            setChooseTireStep(true);
        } else {
            setActiveStep(2);
        }
    }

    // Validation Tire ( 2.5 )
    function tiresSelectedHandler(tires, tireChoice, quotationComment) {
        // console.log("MODal ", tires, tireChoice);
        setSelectedTires(tires);
        setTireChoice(tireChoice);
        setQuotationComment(quotationComment);
        setActiveStep(2);
        setChooseTireStep(false);
    }

    // Validation step3
    function serviceValidatedHandler(service, options, pRetrieveHour, pComment, pPhone, valetComment, canPayByCash) {
        setSelectedService(service);
        setSelectedOptions(options);
        setRetrieveHour(pRetrieveHour);
        setComment(pComment);
        setPhone(pPhone);
        setValetComment(valetComment);
        setCanPayByCash(canPayByCash);
        setActiveStep(3);
    }

    // Validation step4
    function paymentValidatedHandler(paymentType, invoiceDetails = null) {
        setPaymentType(paymentType);
        invoiceDetailsRef.current = invoiceDetails;
        if (paymentType == PaymentType.CREDIT_CARD) // CB
        {
            // On regarde si il a des cartes sauvegardées ..
            requestPaymentMethods();
        } else {
            requestPaymentIntentFromServer(paymentType);
        }
    }

    // Provenant des cartes sauvegardées
    function paymentValidatedFromPaymentMethod() {
        // TODO
    }

    function newMethodClickedHandler() {
        // setShowSavedCards(false);
        setPaymentStep(PaymentStep.NewCard);
    }

    function validatePaymentFromPaymentMethodHandler(paymentMethod) {
        setPaymentMethod(paymentMethod);
    }

    function previousClickedHandler() {
        if (chooseTireStep) {
            setChooseTireStep(false);
            return;
        }
        switch (paymentStep) {
            case PaymentStep.ChooseCard:
            case PaymentStep.NewCard:
                setStripeIntentData(null);
                setPaymentStep(null);
                return;
                break;
        }
        setActiveStep(activeStep - 1);
    }

    function valetSearchHandler(address) {
        setValetUserAddress(address);

        let data = {
            'location_id': selectedLocation.id,
            'address': address
        }

        callPost(apiUri + 'valet_search', data, (resultData => {
            setValetData(resultData.valet_intent);
            // if (resultData.valet_intent.gmap_status == 'OK') {
            //     setValetData(resultData.valet_intent);
            // } else {
            //     setValetData(null);
            // }
        }));
    }

    return (
        <ParentTag isBranded={isBranded}>
            <div style={isBranded ? style_branded : (isMobile ? style_mobile : style)}>
                <Box sx={
                    !isBranded ? styleInner : null
                }>
                    {/* Header */}
                    <Grid container justifyContent="flex-end">
                        <Grid item sx={{flexGrow: 1}}>
                            <Grid container justifyContent="center">
                                <h3>{
                                    !orderDone &&
                                    t(label)
                                }</h3>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {!isBranded &&
                                <ImageButton src={btnClose} srcOver={btnCloseOver} onClickHandler={closeButtonHandler}/>
                            }
                        </Grid>
                    </Grid>
                    {userData &&
                        <>
                            <Box>
                                {orderDone && <ServiceSuccess/>}
                                {!orderDone && contentLoaded &&
                                    <ServiceStepper
                                        activeStep={activeStep}
                                        changeStepHandler={changeStepFromMenuHandler}
                                        withVehicleStep={!isBranded}
                                        withLabels={!(isBranded && isMobile)}
                                    />
                                }

                                {
                                    !orderDone &&
                                    <>
                                        {paymentStep == PaymentStep.ChooseCard ?
                                            <ServiceStepPaymentMethods
                                                previousBtn={previousBtnRef}
                                                nextBtn={nextBtnRef}
                                                newMethodClickedHandler={newMethodClickedHandler}
                                                validatePaymentFromPaymentMethodHandler={validatePaymentFromPaymentMethodHandler}
                                            /> :
                                            <>
                                                {paymentStep == PaymentStep.NewCard ?
                                                    <ServiceStepStripe
                                                        previousBtn={previousBtnRef}
                                                        nextBtn={nextBtnRef}
                                                        orderData={orderData}
                                                        stripeIntentData={stripeIntentData}
                                                    />
                                                    :
                                                    React.cloneElement(getCurrentStepTag(activeStep, chooseTireStep),
                                                        {
                                                            previousBtn: previousBtnRef,
                                                            nextBtn: nextBtnRef,
                                                            contentLoadedHandler: contentLoadedHandler,
                                                            setLabel: setLabel,
                                                            userData: userData,
                                                            validateVehicle: validateVehicle, // Step1 Validation ( +Step2 branded )
                                                            selectedLocation: selectedLocation,
                                                            selectedDay: selectedDay,
                                                            selectedTimeslot: selectedTimeslot,
                                                            validateLocation: locationSelectedHandler,   // Step2 Validation
                                                            validateTires: tiresSelectedHandler,
                                                            tires: selectedTires,
                                                            vehicle: vehicle,
                                                            location: selectedLocation, // TODO Doublon avec selectedLocation ??
                                                            validateService: serviceValidatedHandler,  // Step3 validation
                                                            service: selectedService,
                                                            serviceOptions: selectedOptions,
                                                            day: selectedDay,
                                                            period: selectedTimeslot,
                                                            comment: comment,
                                                            phone: phone,
                                                            retrieveHour: retrieveHour,
                                                            valetComment: valetComment,
                                                            validatePayment: paymentValidatedHandler,  // Step4 validation
                                                            ref: childRef,
                                                            tireChoice: tireChoice,
                                                            quotationComment: quotationComment,
                                                            valetSearchHandler: valetSearchHandler,
                                                            valetData: valetData,
                                                            canPayByCash: canPayByCash,
                                                        })
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                            {!orderDone &&
                                <Grid container justifyContent="center">
                                    <Button ref={previousBtnRef}
                                            color="yellow"
                                            onClick={(e) => previousClickedHandler()}>
                                        <NavigateBeforeIcon sx={
                                            {
                                                color: 'orange',
                                                fontSize: 60,
                                            }}/>
                                        {t('common.previous_step')}
                                    </Button>
                                    <Button
                                        ref={nextBtnRef}
                                        color="yellow"
                                        disabled={isSending}
                                    >
                                        {t('common.next_step')}
                                        <NavigateNextIcon sx={
                                            {
                                                color: 'orange',
                                                fontSize: 60,
                                            }}/>
                                    </Button>
                                </Grid>
                            }
                        </>
                    }
                </Box>
            </div>
        </ParentTag>
    );
}

const style = {
    position: 'absolute',
    top: '2.5%',
    bottom: '2.5%',
    left: '2.5%',
    right: '2.5%',
};

const style_mobile = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
}

// S'intègre dans le flux vertical, mais 0 marge à gauche et droite
const style_branded = {
    width: '100%',
    // backgroundColor: 'red'
    // padding: '0px'
    // position: 'absolute',
    // top: '0px',
    // bottom: '0px',
    // left: '0px',
    // right: '0px',
}

const styleInner = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minHeight: '100%'
}